const TokenKey = 'hk_access_token'
const UserKey = 'hk_user_id'
const HomeUserKey = 'hk_home_user_id'
export function getToken () {
  return sessionStorage.getItem(TokenKey)
}

export function setToken (token) {
  return sessionStorage.setItem(TokenKey, token)
}

export function removeToken () {
  return sessionStorage.removeItem(TokenKey)
}

export function getUserId () {
  return sessionStorage.getItem(UserKey)
}

export function getHomeUserId () {
  return sessionStorage.getItem(HomeUserKey)
}

export function setUserId (userId) {
  return sessionStorage.setItem(UserKey, userId)
}

export function setHomeUserId (userId) {
  return sessionStorage.setItem(HomeUserKey, userId)
}

export function removeUserId () {
  return sessionStorage.removeItem(UserKey)
}
