<template>
  <div id="roomContainer" ref="roomRef" class="room" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
    <div class="header" v-show="showHeaderTool">
      <span>HEUK&nbsp;-&nbsp;{{roomName}}</span>
      <div class="right-container">

        <el-dropdown size="small">
          <span class="el-dropdown-link">
            {{nickName}}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown" style="background-color: #29363a; border: #29363a">
            <el-dropdown-item style="background-color: #29363a; color: #ffffff" @click.native="openNickName">修改昵称</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="content">
      <div class="stream-container-right">
        <div ref="enlargedContainerRef" class="enlarged-stream-container">
          <!-- 左侧全屏 -->
          <stream-region
            v-if="enlargeStream"
            :stream="enlargeStream"
            :style="enlargedStreamStyle"
            :userAudio="userAudio"
            :userId="userId"
            :homeUserId="homeUserId"
            @removeUser="removeUser"
          ></stream-region>
        </div>
        <div class="stream-list-container">
          <div class="stream-list">
            <!-- 右侧列表 -->
            <stream-region
              v-for="(stream) in remoteStreamList"
              :key="`${stream.getUserId()}_${stream.streamType}`"
              :stream="stream"
              :enlarge-dom-id="enlargeDomId"
              :userAudio="userAudio"
              :userId="userId"
              :homeUserId="homeUserId"
              class="single-stream"
              :style="streamStyle"
              @removeUser="removeUser"
            ></stream-region>
            <stream-region-full v-for="(user) in noUserList" :key="user.userId" :user="user" :isHome="isHome" @removeUser="removeUser">
            </stream-region-full>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-container">
        <div class="left-container">
          <div @click="toggleMuteAudio">
            <icon-button :title="'麦克风'">
              <svg-icon :icon-name="isShowMic ? 'mic-on' : 'mic-off'" class="icon-class"></svg-icon>
            </icon-button>
          </div>
          <div @click="toggleMuteCamera">
            <icon-button :title="'摄像头'">
              <svg-icon :icon-name="isShowCamera ? 'camera-on' : 'camera-off'" class="icon-class"></svg-icon>
            </icon-button>
          </div>
        </div>
        <div class="center-container">
          <div v-if="isHome" @click="handleStartScreenShare">
            <icon-button :title="'屏幕共享'">
              <svg-icon icon-name="screen-share" class="icon-class"></svg-icon>
            </icon-button>
          </div>
          <div @click="onFullScreen">
            <icon-button :title="fullScreen ? '退出全屏' : '全屏'">
              <svg-icon :icon-name="fullScreen ? 'exit-fullscreen' : 'full-screen'" class="icon-class"></svg-icon>
            </icon-button>
          </div>
          <div @click="invitation">
            <icon-button :title="'邀请'">
              <svg-icon icon-name="invite" class="icon-class"></svg-icon>
            </icon-button>
          </div>
          <div @click="$EventBus.$emit('clear')">
            <icon-button :title="'清空'">
              <svg-icon icon-name="qingkong" class="icon-class" style="width: 25px;height: 25px;margin: 5px auto;"></svg-icon>
            </icon-button>
          </div>
          <div @click="$EventBus.$emit('undo')">
            <icon-button :title="'撤销'">
              <svg-icon icon-name="chexiao" class="icon-class" style="width: 25px;height: 25px;margin: 5px auto;"></svg-icon>
            </icon-button>
          </div>
          <div @click="onCloudRecording">
            <icon-button :title="taskState !== 1 ? '开始录制' : '结束录制'">
              <svg-icon :icon-name="taskState !== 1 ? 'play-circle-line' : 'rec-start'" class="icon-class" style="width: 25px;height: 25px;margin: 5px auto;"></svg-icon>
            </icon-button>
          </div>
<!--          <div @click="deleteCloudRecording">-->
<!--            <icon-button :title="'结束录制'">-->
<!--              <svg-icon icon-name="rec-start" class="icon-class" style="width: 25px;height: 25px;margin: 5px auto;"></svg-icon>-->
<!--            </icon-button>-->
<!--          </div>-->
        </div>
        <div class="right-container" @click="onLeave">
          <div class="end-button">{{isHome ? '解散房间' : '退出房间'}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import IconButton from '../components/common/IconButton.vue'
import rtc from '../components/mixins/rtc.js'
import share_rtc from '../components/mixins/share-rtc.js'
import signal from '../components/mixins/Signal'
import StreamRegion from "../components/StreamRegion";
import TRTC from 'trtc-js-sdk';
import {awaitWrap} from '../utils/utils'
import room from '@/api/room'
import { setUserId, setHomeUserId, removeToken } from '../utils/authc'
import user from "../api/user";
import TIM from 'tim-js-sdk';
import StreamRegionFull from '../components/StreamRegionFull'
export default {
  name: 'Room',
  mixins: [rtc, share_rtc, signal],
  components: {StreamRegionFull, StreamRegion, IconButton },
  props: {},
  data() {
    return {
      noUserList: [],
      roomName: '',
      homeUserId: '',
      taskState: 0,
      userId: '',
      nickName: '',
      roomId: undefined,
      classId: undefined,
      roomPwd: '',
      // sdkAppId: 1400792190,
      // secretKey: '3a3a61c669f85162ec7270e8d25b56be6205ef2ea00d4538f9e8f549e7031c26',
      sdkAppId: 1400812649,
      secretKey: 'b272744d7553214d87a82b6eee918600df2708d71076e880d7d3f2f3f750799f',
      userSig: '',
      inviteLink: '',
      showHeaderTool: true,
      showRoomTool: true,
      isShowMic: false,
      isShowCamera: true,
      showIconControl: true,
      isPlayingLocalStream: true,
      streamStyle: {},
      userAudio: {},
      fullScreen: false,
      showFullStream: true,
      enlargeDomId: '',
      enlargeStream: null,
      streamContainerClass: 'stream-container-flatten',
      enlargedStreamStyle: {},
      isHome: false,
      allHomeUserList: []
    }
  },
  computed: {
    ...mapGetters([
      'getClassInfo'
    ])
  },
  watch: {
    'remoteStreamList.length'(){
      if (this.showFullStream) {
        this.handleRightSideListLayout()
      }
    }
  },
  created() {
    this.roomId = Number(this.$route.query.roomId)
    this.classId = Number(this.$route.query.roomId)
    this.roomPwd = this.$route.query.roomPwd
    this.userId = this.$route.query.userId
    if (this.roomId && this.roomPwd ) {
      this.createRoom()
    } else {
      this.$msgbox.alert('非法操作')
      this.$router.push({
        path: '/'
      })
    }
    this.getDeviceList('camera')
    this.getDeviceList('microphone')
    this.getDeviceList('speaker')
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleWindowClose)
  },
  mounted() {
    ['resize', 'pageshow'].forEach((event) => {
      if (this.showFullStream) {
        window.addEventListener(event, this.handleRightSideListLayout())
      }
    })
    window.addEventListener('beforeunload', this.handleWindowClose)
  },
  methods: {
    setSignalReady(bol){
      this.$store.dispatch('setSignalReady', bol);
    },
    // 关闭浏览器
    handleWindowClose() {
      // 检查标志是否被设置
      // 自定义浏览器关闭事件的逻辑
      // 你可以使用 `event.returnValue` 弹出确认消息
      // this.onLeave()
    },
    async getDeviceList(deviceType) {
      switch (deviceType) {
        case 'camera':
          this.deviceList = await TRTC.getCameras();
          break;
        case 'microphone':
          this.deviceList = await TRTC.getMicrophones();
          break;
        case 'speaker':
          this.deviceList = await TRTC.getSpeakers();
          break;
        default:
          break;
      }
      console.log(deviceType, this.deviceList)
    },
    // 获取房间信息
    getRoomInfo() {
      room.getRoomInfo(this.roomId).then(async data => {
        if (data.result === '1') {
          this.roomName = data.data.roomName
          this.createRoom()
        } else {
          this.$msgbox.alert(data.data, {
            callback: action => {
              console.log(action)
              this.$router.back()
            }
          })
          return
        }
      })
    },
    async joinClassroom() {
      // 基础数据
      this.$store.commit('classInfo', {
        sdkAppId: Number(this.sdkAppId),
        userId: String(this.userId),
        classId: Number(this.classId),
        userSig: String(this.userSig),
        nickname: String(this.nickName),
      })
      // 1. 准备好信令通道
      let error;
      // 1. 准备好信令通道
      [error] = await awaitWrap(this.quitSignal());
      if (error) {
        console.error(
          error.code,
          error.message,
          'TIM的错误码文档: https://cloud.tencent.com/document/product/269/1671',
        );
        return
      } else {
        this.$store.dispatch('setSignalReady', true);
      }
      // 2. 登录
      [error] = await awaitWrap(this.joinSignal());
      if (error) {
        console.error(
          error.code,
          error.message,
          'TIM的错误码文档: https://cloud.tencent.com/document/product/269/1671',
        );
        return
      } else {
        // 3. 加入课堂
        [error] = await awaitWrap(this.createAndJoinSignal());
        if (error) {
          console.error(
            error.code,
            error.message,
            'TIM的错误码文档: https://cloud.tencent.com/document/product/269/1671',
          );
          return
        }
      }
    },
    async createRoom () {
      room.joinRoom(this.roomId, this.roomPwd).then(async data => {
        if (data.result === '1') {
          this.userId = data.data.userId
          this.homeUserId = data.data.homeUserId
          this.taskState = data.data.taskState
          this.isHome = data.data.isHome
          this.roomName = data.data.roomName
          setUserId(this.userId)
          setHomeUserId(this.homeUserId)
          this.nickName = data.data.userInfo ? data.data.userInfo.nickName : data.data.userId
          this.userSig = data.data.userSig
          this.sdkAppId = data.data.sdkAppId
          if (data.data.recording) {
            this.$message.success('主持人已开启会议录制')
          }
          // 准备 白板数据
          await this.joinClassroom()

          await this.initClient()
          await this.join()
          await this.initLocalStream()
          await this.playLocalStream()
          await this.publish()
          // 默认静音
          await this.muteAudio()
        } else {
          this.$msgbox.alert(data.data, {
            callback: action => {
              console.log(action)
              this.$router.back()
            }
          })
          return
        }
      })
    },
    // 点击【开始屏幕分享】按钮
    async handleStartScreenShare() {
      if (!this.sdkAppId) {
        return;
      }
      await this.initShareClient();
      await this.initShareLocalStream();
      await this.handleShareJoin();
      await this.handleSharePublish();
    },

    // 点击【停止屏幕分享按钮】
    async handleStopScreenShare() {
      await this.handleShareUnpublish();
      await this.handleShareLeave();
    },
    // 创建成功
    addSuccessLog(log) {
      console.log(log)
    },
    // 测速
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId
      })
    },
    // 显示失败的 Log
    addFailedLog(log) {
      console.error(log)
    },
    onMouseenter() {
      this.showHeaderTool = true
      this.showRoomTool = true
    },
    reportFailedEvent (name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    onMouseleave() {
      this.showHeaderTool = false
      this.showRoomTool = false
    },
    async toggleMuteAudio() {
      if (this.isShowMic) {
        await this.muteAudio()
        this.isShowMic = false
      } else {
        await this.unmuteAudio()
        this.isShowMic = true
      }
    },
    muteIcoAudio(userId, audio) {
      this.userAudio = {
        audio: audio,
        userId: userId
      }
    },
    async toggleMuteCamera() {
      if (this.isShowCamera) {
        await this.muteVideo()
        this.isShowCamera = false
      } else {
        await this.unmuteVideo()
        this.isShowCamera = true
      }
    },
    // 全屏模块
    onFullScreen() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.fullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        console.log('已还原！');
      } else {    // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
        console.log('已全屏！');
      }
      // 改变当前全屏状态
      this.fullScreen = !this.fullScreen;
    },
    // 邀请链接
    invitation() {
      let that = this
      let url = window.location.origin + '/#/index?roomId=' + this.roomId
      this.$alert('<p>邀请您加入【' + this.roomName + '】的会议，请点击链接参加：' + url + '，会议密码为：' + this.roomPwd + '</p>' +
        '<p>点击确定复制链接</p>', '邀请链接', {
        dangerouslyUseHTMLString: true,
        showClose: false
      }).then(() => {
        url = '邀请您加入【' + this.roomName + '】的会议，请点击链接参加：' + url + '，会议密码为：' + this.roomPwd
        navigator.clipboard.writeText(url)
          .then(() => {
            that.$message({
              message: '链接已复制到剪贴板',
              type: 'success'
            })
            console.log('链接已复制到剪贴板');
          })
          .catch(err => {
            console.error('不能复制链接: ', err);
          });
      })
    },
    backLayout () {
      this.showFullStream = false
      this.streamContainerClass = 'stream-container-flatten'
      this.enlargeStream = {}
      this.enlargeDomId = ''
    },
    onCloudRecording () {
      if (this.taskState !== 1) {
        this.createCloudRecording()
      } else {
        this.deleteCloudRecording()
      }
    },
    // 开始录制
    createCloudRecording() {
      room.createCloudRecording(this.roomId).then(data => {
        if (data.result === '1') {
          this.taskState = 1
          this.$msgbox.alert('开启成功')
        } else {
          this.$msgbox.alert(data.data)
        }
      })
    },
    // 结束录制
    deleteCloudRecording() {
      room.deleteCloudRecording(this.roomId).then(data => {
        if (data.result === '1') {
          this.taskState = 2
          this.$msgbox.alert('结束成功')
        } else {
          this.$msgbox.alert(data.data)
        }
      })
    },
    removeUser (userId, nickName) {
      if (this.isHome) {
        this.$confirm('是否移除' + nickName + '用户?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info'
        }).then(() => {
          room.removeUser(this.roomId, userId).then(data => {
            if (data.result === '1') {
              this.$msgbox.alert('移除' + nickName + '成功')
            } else {
              this.$msgbox.alert(data.data)
            }
          })
        })
      }
    },
    async dismissGrop() {
      this.tim.dismissGroup(String(this.roomId)).then(data => {
        console.log('解散成功', data.data.groupID)
      }).catch(function(imError) {
        console.warn('dismissGroup error:', imError); // 解散群组失败的相关信息
      })
    },
    // 退出房间
    async onLeave () {
      if (this.isHome) {
        room.dismissRoom(this.roomId).then(data => {
          if (data.result === '1') {
            removeToken()
            this.$msgbox.alert('解散成功').then(() => {
              this.dismissGrop()
              this.$router.push({
                path: '/'
              })
            })

          } else {
            this.$msgbox.alert(data.data)
          }
        })
      } else {
        await this.leave()
        if (!this.isLeaving) {
          this.$alert('<p>' + '您已退出房间' + '</p>', '提示', {
            dangerouslyUseHTMLString: true,
            showClose: false
          }).then(() => {
            removeToken()
            this.$router.push({
              path: '/index?roomId=' + this.roomId
            })
          })
        }
      }
    },
    openNickName() {
      this.$prompt('请输入昵称', '修改昵称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (!value) {
          this.$msgbox.alert('昵称不能为空')
          return
        }
        user.saveUserInfo(value).then(data => {
          if (data.result === '1') {
            this.nickName = value
            const item = {
              userId: this.userId,
              nickName: this.nickName
            }
            this.$EventBus.$emit('update_nick_name', JSON.stringify(item));
            this.$EventBus.$emit('update_nick_name1', JSON.stringify(item));
            const message = this.tim.createCustomMessage({
              to: String(this.classId),
              conversationType: TIM.TYPES.CONV_GROUP,
              priority: TIM.TYPES.MSG_PRIORITY_HIGH,  // 因为im消息有限频，白板消息的优先级调整为最高
              payload: {
                data: JSON.stringify(item),
                description: '',
                extension: 'UpdateNickName',
              }
            })
            // 发送消息
            this.tim.sendMessage(message).then((data) => {
              console.log(data)
            }, (error) => {
              console.log(error)
            });
          }
        })
      }).catch(() => {
      })
    },
    /**
     * Handle sidebar layout
     *
     * 处理侧边栏布局
     **/
    async handleRightSideListLayout() {
      this.streamContainerClass = 'stream-container-right';

      this.$nextTick(() => {
        this.streamStyle = {};
        if (this.$refs.enlargedContainerRef) {
          const containerWidth = this.$refs.enlargedContainerRef.offsetWidth;
          const containerHeight = this.$refs.enlargedContainerRef.offsetHeight;
          let width = 0;
          let height = 0;
          const scaleWidth = containerWidth / 16;
          const scaleHeight = containerHeight / 9;
          if (scaleWidth > scaleHeight) {
            width = (containerHeight / 9) * 16;
            height = containerHeight;
          }
          if (scaleWidth < scaleHeight) {
            width = containerWidth;
            height = (containerWidth / 16) * 9;
          }
          this.enlargedStreamStyle.width = `${width}px`;
          this.enlargedStreamStyle.height = `${height}px`;
        }
      })
    }
  }
}
</script>
<style>
.el-popper[x-placement^=bottom] .popper__arrow {
  border-bottom-color: #29363a!important;
}
.el-popper[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: #29363a!important;
}

</style>
<style lang="scss" scoped>
.room {
  width: 100%;
  height: 100%;
  position: relative;
}
* {
  color: #B3B8C8;
  box-sizing: border-box;
  text-align: start;
  user-select: none;
  font-family: PingFangSC-Medium;
}

.header {
  width: 100%;
  height: 48px;
  line-height: 48px;
  background-color: #1D2029;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  padding-left: 20px;
  font-weight: bold;
  .right-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    float: right;
    padding-right: 24px;
    display: flex;
    align-items: center;
    > :not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.content {
  width: 100%;
  height: 100%;
  background-color: #0D0F15;
  .stream-container-flatten {
    width: 100%;
    height: 100%;
    background-color: #0D0F15;
    overflow: hidden;
    .stream-list-container {
      width: 100%;
      height: 100%
    }
    .stream-list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      .single-stream {
        padding: 4px;
      }
    }
  }
  .stream-container-right {
    width: 100%;
    height: 100%;
    background-color: #0D0F15;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    .enlarged-stream-container {
      width: calc(100% - 260px);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .stream-list-container {
      width: 260px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      padding: 48px 10px 80px;
      background-color: #1D2029;
      display: flex;
      align-items: center;
      &.hide-list {
        transform: translateX(260px);
      }
      &::before {
        content: '';
        width: 100%;
        height: 40px;
        position: absolute;
        top: 48px;
        left: 0;
        opacity: 0.1;
      }
    }
    .stream-list {
      max-width: 100%;
      max-height: 100%;
      overflow-y: scroll;
      padding: 10px 0;
      &::-webkit-scrollbar {
        display: none;
      }
      .single-stream {
        width: 240px;
        height: 135px;
        border-radius: 4px;
        overflow: hidden;
        &:not(:first-child) {
          margin-top: 14px;
        }
      }
    }
  }
}



.footer {
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #1D2029;
  .footer-container {
    width: 100%;
    height: 100%;
    padding-left: 9px;
    padding-right: 24px;
    display: flex;
    justify-content: space-between;

    .left-container {
      height: 100%;
      display: flex;
      align-items: center;

      > :not(:first-child) {
        margin-left: 1rem;
      }
    }

    .center-container {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      margin: 0 auto;
    }

    .right-container {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
}
.icon-class {
  color: #fff;
  cursor: pointer;
  width: 35px;
  height: 35px;
}
.end-button {
  width: 90px;
  height: 40px;
  border: 2px solid #FF2E2E;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  color: #FF2E2E;
  letter-spacing: 0;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
  &:hover {
    background-color: #FF2E2E;
    color: #FFFFFF;
  }
}
.remote-container {
  width: 100%;
  margin: 10px;
  display: flex;
  flex-wrap: wrap;
  .remote-stream-container {
    width: 320px;
    height: 240px;
    margin: 0 10px 10px 0;
  }
}
</style>
