import request from '@/utils/request'
import qs from 'qs'
export default {
  login (userName, passWord) {
    return request({
      url: 'login',
      method: 'post',
      data: qs.stringify({
        userName: userName,
        password: passWord
      })
    })
  },
  getRoomInfo (roomId) {
    return request({
      url: 'room/getRoomInfo',
      method: 'post',
      params: {
        roomId: roomId
      }
    })
  },
  createRoom (roomPwd, roomName) {
    return request({
      url: 'room/createRoom',
      method: 'post',
      params: {
        roomName: roomName,
        roomPwd: roomPwd
      }
    })
  },
  joinRoom (roomId, roomPwd) {
    return request({
      url: 'room/joinRoom',
      method: 'post',
      params: {
        roomId: roomId,
        roomPwd: roomPwd
      }
    })
  },
  // 移出用户
  removeUser (roomId, userId) {
    return request({
      url: 'room/removeUser',
      method: 'post',
      params: {
        roomId: roomId,
        userId: userId
      }
    })
  },
  // 解散房间
  dismissRoom (roomId) {
    return request({
      url: 'room/dismissRoom',
      method: 'post',
      params: {
        roomId: roomId
      }
    })
  },
  // 开始录制
  createCloudRecording (roomId) {
    return request({
      url: 'room/createCloudRecording',
      method: 'post',
      params: {
        roomId: roomId
      }
    })
  },
  // 结束录制
  deleteCloudRecording (roomId) {
    return request({
      url: 'room/deleteCloudRecording',
      method: 'post',
      params: {
        roomId: roomId
      }
    })
  }
}
