// 这里要注意不要在mutations里面进行异步操作，只是用来改变state的值
import * as TYPES from './mutation-types'
import Vue from 'vue'

export default {
  [TYPES.ADD_CLIENT] (state, payload) {
    Vue.set(state, 'client', payload)
  },
  setOtherNickName(state, payload) {
    Vue.set(state, 'otherNickName', payload)
  },
  setSignalReady(state, payload) {
    Vue.set(state, 'isSignalReady', payload)
  },
  classInfo(state, payload) {
    sessionStorage.setItem('TIW_CLASSINFO_USERID', payload.userId);
    sessionStorage.setItem('TIW_CLASSINFO_USERSIG', payload.userSig);
    sessionStorage.setItem('TIW_CLASSINFO_CLASSID', payload.classId);
    sessionStorage.setItem('TIW_CLASSINFO_NICKNAME', payload.nickname);
    Vue.set(state, 'classInfo', payload)
  }
}
