<template>
  <div class="user-stream-container single-stream" style="text-align: center">
    <svg-icon icon-name="camera-off" class="icon-class" style="font-size: 50px;margin-top: 15px"></svg-icon>
    <div class="corner-user-info-container">
      <span class="user-name">{{ nickName }}</span>
      <span v-if="isHome" class="el-icon-close" @click="onRemoveUser(user.userId, nickName)"></span>
    </div>
  </div>
</template>

<script>
import user from '@/api/user'
export default {
  components: {
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    isHome: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      nickName: ''
    };
  },
  computed: {
    // Similar computed properties
    // ...
  },
  watch: {
  },
  created() {
    this.$nextTick(() => {
      user.getUserInfo(this.user.userId).then(data => {
        if (data.result === '1') {
          this.nickName = data.data ? data.data.nickName : '游客'
          console.log(this.nickName)
        }
      })
    })
  },
  methods: {
    onRemoveUser (userId, nickName) {
      this.$emit('removeUser', userId, nickName);
    },
    updateNickName (item) {
      console.log(item)
      let user = JSON.parse(item)
      if (this.user.userId === user.userId) {
        this.nickName = user.nickName
      }
    }
  },
  mounted() {
    this.$EventBus.$on("update_nick_name1", this.updateNickName)
  }
}
</script>

<style lang="scss" scoped>
  .user-stream-container {
    position: relative;
    background-color: #222222;
    .single-stream {
      padding: 4px;
    }
    .stream-region {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .corner-user-info-container {
      position: absolute;
      bottom: 4px;
      left: 4px;
      min-width: 118px;
      max-width: 100%;
      overflow: hidden;
      height: 30px;
      display: flex;
      background: rgba(0,0,0,0.60);
      color: #FFFFFF;
      align-items: center;
      align-content: center;
      padding: 0 10px 0 0;
      font-size: 14px;
      > *{
        margin-left: 8px;
        max-width: 160px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .master-icon {
        margin-left: -1px;
        width: 30px;
        height: 25px;
        margin-top: -4px;
      }
      .screen-icon {
        transform: scale(0.8);
      }
    }
    .center-user-info-container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #0D0F15;
      .avatar-region {
        width: 130px;
        height: 130px;
        border-radius: 50%;
      }
      .user-info {
        width: 130px;
        text-align: center;
        .avatar-region {
          width: 130px;
          height: 130px;
          border-radius: 50%;
        }
        .user-gender-name {
          svg {
            vertical-align: bottom;
          }
          .user-name {
            display: inline-block;
            margin-left: 6px;
            font-size: 14px;
            font-weight: 400;
            max-width: 100px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            vertical-align: bottom;
          }
        }
      }
    }
  }
</style>
