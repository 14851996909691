<template>
  <div id="roomContainer" ref="roomRef" class="room" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
    <div class="header">
      <van-nav-bar
        safe-area-inset-top
        :title="roomName"
      />
    </div>
    <div class="content">
      <div v-show="!fullScreen">
        <stream-region
          v-if="enlargeStream"
          :stream="enlargeStream"
          :style="enlargedStreamStyle"
          :userAudio="userAudio"
          :userId="userId"
          :homeUserId="homeUserId"
        ></stream-region>
      </div>
      <div v-show="fullScreen">
        <van-row gutter="20">
          <van-col span="12"
                   v-for="(stream) in remoteStreamList"
                   :key="`${stream.getUserId()}_${stream.streamType}`"
          >
            <stream-region
              :stream="stream"
              :enlarge-dom-id="enlargeDomId"
              :userAudio="userAudio"
              :userId="userId"
              :homeUserId="homeUserId"
              class="single-stream"
              :style="streamStyle"
            ></stream-region>
            <stream-region-full v-for="(user) in noUserList" :key="user.userId" :user="user" :isHome="isHome">
            </stream-region-full>
          </van-col>
        </van-row>
      </div>
    </div>
    <div>
      <van-tabbar placeholder safe-area-inset-bottom v-model="active">
        <van-tabbar-item @click.native="toggleMuteAudio">
          <span>麦克风</span>
          <template #icon>
            <svg-icon :icon-name="isShowMic ? 'mic-on' : 'mic-off'" class="icon-class"></svg-icon>
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click.native="toggleMuteCamera">
          <span>摄像头</span>
          <template #icon>
            <svg-icon :icon-name="isShowCamera ? 'camera-on' : 'camera-off'" class="icon-class"></svg-icon>
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click.native="onFullScreen">
          <span>切换</span>
          <template #icon>
            <svg-icon :icon-name="fullScreen ? 'exit-fullscreen' : 'full-screen'" class="icon-class"></svg-icon>
          </template>
        </van-tabbar-item>
        <van-tabbar-item @click.native="onLeave">
          <span>退出</span>
          <template #icon>
            <svg-icon icon-name="screen-share" class="icon-class"></svg-icon>
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import rtc from '../../components/mixins/rtc.js'
import share_rtc from '../../components/mixins/share-rtc.js'
import signal from '../../components/mixins/Signal'
import StreamRegion from "../../components/StreamRegion";
import TRTC from 'trtc-js-sdk';
import {awaitWrap} from '../../utils/utils'
import room from '@/api/room'
import { setUserId, setHomeUserId, removeToken } from '../../utils/authc'
import user from "../../api/user";
import TIM from 'tim-js-sdk';
import { Dialog } from 'vant';
export default {
  name: 'ModelRoom',
  mixins: [rtc, share_rtc, signal],
  components: { StreamRegion, [Dialog.Component.name]: Dialog.Component },
  props: {},
  data() {
    return {
      active: 0,
      noUserList: [],
      roomName: '',
      homeUserId: '',
      taskState: 0,
      userId: '',
      nickName: '',
      roomId: undefined,
      classId: undefined,
      roomPwd: '',
      // sdkAppId: 1400792190,
      // secretKey: '3a3a61c669f85162ec7270e8d25b56be6205ef2ea00d4538f9e8f549e7031c26',
      sdkAppId: 1400812649,
      secretKey: 'b272744d7553214d87a82b6eee918600df2708d71076e880d7d3f2f3f750799f',
      userSig: '',
      inviteLink: '',
      showHeaderTool: true,
      showRoomTool: true,
      isShowMic: false,
      isShowCamera: true,
      showIconControl: true,
      isPlayingLocalStream: true,
      streamStyle: {},
      userAudio: {},
      fullScreen: false,
      showFullStream: true,
      enlargeDomId: '',
      enlargeStream: null,
      streamContainerClass: 'stream-container-flatten',
      enlargedStreamStyle: {},
      isHome: false,
      allHomeUserList: []
    }
  },
  computed: {
    ...mapGetters([
      'getClassInfo'
    ])
  },
  watch: {
    'remoteStreamList.length'(){
      if (this.showFullStream) {
        this.handleRightSideListLayout()
      }
    }
  },
  created() {
    this.roomId = Number(this.$route.query.roomId)
    this.classId = Number(this.$route.query.roomId)
    this.roomPwd = this.$route.query.roomPwd
    this.userId = this.$route.query.userId
    if (this.roomId && this.roomPwd ) {
      this.createRoom()
    } else {
      Dialog({ message: '非法操作' })
      this.$router.push({
        path: '/'
      })
    }
    this.getDeviceList('camera')
    this.getDeviceList('microphone')
    this.getDeviceList('speaker')
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleWindowClose)
  },
  mounted() {
    ['resize', 'pageshow'].forEach((event) => {
      if (this.showFullStream) {
        window.addEventListener(event, this.handleRightSideListLayout())
      }
    })
    window.addEventListener('beforeunload', this.handleWindowClose)
  },
  methods: {
    setSignalReady(bol){
      this.$store.dispatch('setSignalReady', bol);
    },
    changeButton(index){
      switch (index) {
        case 0:
          this.toggleMuteAudio()
          break
        case 1:
          this.toggleMuteCamera()
          break
        case 2:
          this.onFullScreen()
          break
        case 3:
          this.onLeave()
          break
      }
    },
    // 关闭浏览器
    handleWindowClose() {
      // 检查标志是否被设置
      // 自定义浏览器关闭事件的逻辑
      // 你可以使用 `event.returnValue` 弹出确认消息
      // this.onLeave()
    },
    async getDeviceList(deviceType) {
      switch (deviceType) {
        case 'camera':
          this.deviceList = await TRTC.getCameras();
          break;
        case 'microphone':
          this.deviceList = await TRTC.getMicrophones();
          break;
        case 'speaker':
          this.deviceList = await TRTC.getSpeakers();
          break;
        default:
          break;
      }
      console.log(deviceType, this.deviceList)
    },
    // 获取房间信息
    getRoomInfo() {
      room.getRoomInfo(this.roomId).then(async data => {
        if (data.result === '1') {
          this.roomName = data.data.roomName
          this.createRoom()
        } else {
          this.$msgbox.alert(data.data, {
            callback: action => {
              console.log(action)
              this.$router.back()
            }
          })
          return
        }
      })
    },
    async joinClassroom() {
      // 基础数据
      this.$store.commit('classInfo', {
        sdkAppId: Number(this.sdkAppId),
        userId: String(this.userId),
        classId: Number(this.classId),
        userSig: String(this.userSig),
        nickname: String(this.nickName),
      })
      // 1. 准备好信令通道
      let error;
      // 1. 准备好信令通道
      [error] = await awaitWrap(this.quitSignal());
      if (error) {
        console.error(
          error.code,
          error.message,
          'TIM的错误码文档: https://cloud.tencent.com/document/product/269/1671',
        );
        return
      } else {
        this.$store.dispatch('setSignalReady', true);
      }
      // 2. 登录
      [error] = await awaitWrap(this.joinSignal());
      if (error) {
        console.error(
          error.code,
          error.message,
          'TIM的错误码文档: https://cloud.tencent.com/document/product/269/1671',
        );
        return
      } else {
        // 3. 加入课堂
        [error] = await awaitWrap(this.createAndJoinSignal());
        if (error) {
          console.error(
            error.code,
            error.message,
            'TIM的错误码文档: https://cloud.tencent.com/document/product/269/1671',
          );
          return
        }
      }
    },
    async createRoom () {
      room.joinRoom(this.roomId, this.roomPwd).then(async data => {
        if (data.result === '1') {
          this.userId = data.data.userId
          this.homeUserId = data.data.homeUserId
          this.taskState = data.data.taskState
          this.isHome = data.data.isHome
          this.roomName = data.data.roomName
          setUserId(this.userId)
          setHomeUserId(this.homeUserId)
          this.nickName = data.data.userInfo ? data.data.userInfo.nickName : data.data.userId
          this.userSig = data.data.userSig
          this.sdkAppId = data.data.sdkAppId
          if (data.data.recording) {
            this.$message.success('主持人已开启会议录制')
          }
          // 准备 白板数据
          await this.joinClassroom()

          await this.initClient()
          await this.join()
          await this.initLocalStream()
          await this.playLocalStream()
          await this.publish()
          // 默认静音
          await this.muteAudio()
        } else {
          this.$msgbox.alert(data.data, {
            callback: action => {
              console.log(action)
              this.$router.back()
            }
          })
          return
        }
      })
    },
    // 创建成功
    addSuccessLog(log) {
      console.log(log)
    },
    // 测速
    reportSuccessEvent(name) {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId
      })
    },
    // 显示失败的 Log
    addFailedLog(log) {
      console.error(log)
    },
    onMouseenter() {
      this.showHeaderTool = true
      this.showRoomTool = true
    },
    reportFailedEvent (name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: 'webrtcQuickDemoVue2',
        ext3: this.sdkAppId,
      });
    },
    onMouseleave() {
      this.showHeaderTool = false
      this.showRoomTool = false
    },
    async toggleMuteAudio() {
      if (this.isShowMic) {
        await this.muteAudio()
        this.isShowMic = false
      } else {
        await this.unmuteAudio()
        this.isShowMic = true
      }
    },
    muteIcoAudio(userId, audio) {
      this.userAudio = {
        audio: audio,
        userId: userId
      }
    },
    async toggleMuteCamera() {
      if (this.isShowCamera) {
        await this.muteVideo()
        this.isShowCamera = false
      } else {
        await this.unmuteVideo()
        this.isShowCamera = true
      }
    },
    backLayout () {
      this.showFullStream = false
      this.streamContainerClass = 'stream-container-flatten'
      this.enlargeStream = {}
      this.enlargeDomId = ''
    },
    // 全屏模块
    onFullScreen() {
      this.fullScreen = !this.fullScreen;
    },
    // 退出房间
    async onLeave () {
      if (this.isHome) {
        room.dismissRoom(this.roomId).then(data => {
          if (data.result === '1') {
            removeToken()
            this.$msgbox.alert('解散成功').then(() => {
              this.dismissGrop()
              this.$router.push({
                path: '/'
              })
            })

          } else {
            this.$msgbox.alert(data.data)
          }
        })
      } else {
        await this.leave()
        if (!this.isLeaving) {
          Dialog.alert({
            title: '提示',
            message: '<p>' + '您已退出房间' + '</p>',
          }).then(() => {
            removeToken()
            this.$router.push({
              path: '/index?roomId=' + this.roomId
            })
          })
        }
      }
    },
    openNickName() {
      this.$prompt('请输入昵称', '修改昵称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        if (!value) {
          this.$msgbox.alert('昵称不能为空')
          return
        }
        user.saveUserInfo(value).then(data => {
          if (data.result === '1') {
            this.nickName = value
            const item = {
              userId: this.userId,
              nickName: this.nickName
            }
            this.$EventBus.$emit('update_nick_name', JSON.stringify(item));
            this.$EventBus.$emit('update_nick_name1', JSON.stringify(item));
            const message = this.tim.createCustomMessage({
              to: String(this.classId),
              conversationType: TIM.TYPES.CONV_GROUP,
              priority: TIM.TYPES.MSG_PRIORITY_HIGH,  // 因为im消息有限频，白板消息的优先级调整为最高
              payload: {
                data: JSON.stringify(item),
                description: '',
                extension: 'UpdateNickName',
              }
            })
            // 发送消息
            this.tim.sendMessage(message).then((data) => {
              console.log(data)
            }, (error) => {
              console.log(error)
            });
          }
        })
      }).catch(() => {
      })
    },
    /**
     * Handle sidebar layout
     *
     * 处理侧边栏布局
     **/
    async handleRightSideListLayout() {
      this.streamContainerClass = 'stream-container-right';

      this.$nextTick(() => {
        this.streamStyle = {};
        if (this.$refs.enlargedContainerRef) {
          const containerWidth = this.$refs.enlargedContainerRef.offsetWidth;
          const containerHeight = this.$refs.enlargedContainerRef.offsetHeight;
          let width = 0;
          let height = 0;
          const scaleWidth = containerWidth / 16;
          const scaleHeight = containerHeight / 9;
          if (scaleWidth > scaleHeight) {
            width = (containerHeight / 9) * 16;
            height = containerHeight;
          }
          if (scaleWidth < scaleHeight) {
            width = containerWidth;
            height = (containerWidth / 16) * 9;
          }
          this.enlargedStreamStyle.width = `${width}px`;
          this.enlargedStreamStyle.height = `${height}px`;
        }
      })
    }
  }
}
</script>
<style>
.van-nav-bar {
  background-color: #1D2029;
}
.van-nav-bar__title {
  color: #fff;
}
.van-tabbar {
  background-color: #1D2029;
  height: 70px;
}
.van-tabbar-item--active {
  background-color: #1D2029;
}
.van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.van-hairline--top-bottom::after {
  border-width: 0px 0;
}
</style>
<style lang="scss" scoped>
.room {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: rgb(48, 48, 48);
}
* {
  color: #B3B8C8;
  box-sizing: border-box;
  text-align: start;
  user-select: none;
  font-family: PingFangSC-Medium;
}
.content {
  width: 100%;
  height: 100%;
  background-color: #0D0F15;
}
</style>
