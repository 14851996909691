<template>
  <div class="home-container">
    <div class="control-container">
      <div class="control-content">
<!--        <img class="logo" src="../assets/image/logo.png"/>-->
        <div class="logo" style="text-align: center;font-weight: bold;font-size: 80px;color: #ffffff">
          <span>HEUK</span>
        </div>
        <div v-if="hasGivenRoomId" class="control-region">
          <span class="invite-title">是否进入房间?</span>
          <span class="invite-info">您被邀请进入 {{roomId}} 房间</span>
<!--          <div class="button enter-given-room-button" @click="enterGivenRoom">-->
<!--            <span class="title">确定进入</span>-->
<!--          </div>-->
          <div class="button-t join-name-button" type="primary" @click="enterGivenRoom">
            <input v-model="userName" class="input" placeholder="输入手机号" maxlength="11" @input="checkInput"
                   @click.stop="">
          </div>
          <div class="button-t join-room-button" type="primary" @click="enterGivenRoom">
            <input v-model="roomPwd" class="input" placeholder="输入房间密码" maxlength="10"
                   @click.stop="">
            <span class="title">确定进入</span>
          </div>
        </div>
        <div v-else class="control-region">
          <div class="create-name-button">
            <el-input v-model="userName" class="input" placeholder="输入账号" maxlength="10" prefix-icon="el-icon-user" />
            <el-input v-model="passWord" class="input" placeholder="输入密码" maxlength="10" prefix-icon="el-icon-lock" />
            <el-input v-model="roomName" class="input" placeholder="输入会议标题" maxlength="10" prefix-icon="el-icon-edit-outline" />
            <el-input v-model="roomPwd" class="input" placeholder="输入会议密码" maxlength="10" prefix-icon="el-icon-set-up" />
          </div>
          <div type="primary" class="button create-room-button">
            <div class="create-room" @click="enterRoom">
              <i class="el-icon-circle-plus-outline" style="font-size: 30px;font-weight: bold;color: #ffffff"></i>
              <span class="title">新建房间</span>
            </div>
            <div class="connect-region"></div>
          </div>
<!--          <div class="button join-room-button" type="primary" @click="enterRoom">-->
<!--            <input v-model="roomId" class="input" placeholder="输入房间号" maxlength="10"-->
<!--                   @click.stop="">-->
<!--            <span class="title">进入房间</span>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import room from '@/api/room'
import user from '@/api/user'
import { setToken } from '../utils/authc'
export default {
  name: 'Index',
  components: {},
  props: {},
  data() {
    return {
      hasGivenRoomId: false,
      userName: '',
      passWord: '',
      roomName: '',
      roomPwd: '',
      userId: '',
      roomId: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    if (this.$route.query.roomId && this.$route.query.roomId !== '') {
      this.hasGivenRoomId = true
      this.roomId = this.$route.query.roomId
    }
  },
  mounted () {
  },
  methods: {
    checkInput() {
      this.userName = this.userName.replace(/[^0-9]/g, '');
    },
    enterGivenRoom () {
      if (!this.userName) {
        this.$message.error({
          message: '请输入手机号'
        })
        return
      }
      if (!this.roomPwd) {
        this.$message.error({
          message: '请输入会议密码'
        })
        return
      }
      user.guestLogin(this.userName).then(data => {
        if (data.result === '1') {
          setToken(data.data.token)
          if (this.isMobileDevice()) {
            // 如果是移动设备，跳转到移动版页面
            this.$router.push({
              path: '/modelRoom?roomId=' + this.roomId + '&roomPwd=' + this.roomPwd
            })
          } else {
            // 如果是 PC 设备，跳转到 PC 版页面
            this.$router.push({
              path: '/room?roomId=' + this.roomId + '&roomPwd=' + this.roomPwd
            })
          }
        }
      })
    },
    async enterRoom () {
      if (this.validateInfo()) {
        room.login(this.userName, this.passWord).then(data => {
          if (data.result === '1') {
            setToken(data.data.token)
            this.createRoom()
          } else {
            this.$message.error({
              message: '账号信息错误'
            })
            return
          }
        })
      }
    },
    isMobileDevice() {
      const userAgent = navigator.userAgent;
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    },
    createRoom () {
      room.createRoom(this.roomPwd, this.roomName).then(suc => {
        if (suc.result === '1') {
          this.$router.push({
            path: '/room?roomId=' + suc.data + '&roomPwd=' + this.roomPwd
          })
        } else {
          this.$message.error({
            message: '存在未结束的会议，请先结束会议'
          })
          this.$router.push({
            path: '/room?roomId=' + suc.data.roomId + '&roomPwd=' + suc.data.roomPsw
          })
        }
      })
    },
    // 校验用户名、密码
    validateInfo () {
      if (this.userName === '') {
        this.$message.warning({
          message: '请输入正确的用户名'
        })
        return false
      } else if (this.passWord === '') {
        this.$message.warning({
          message: '请输入密码'
        })
        return false
      } else if (this.roomPwd === '') {
        this.$message.warning({
          message: '请输入房间密码'
        })
        return false
      }
      return true
    }
  }
}
</script>
<style lang="scss">
.create-name-button {
  .el-input__inner {
    background: rgba(27, 30, 38, 1)!important;
    border-color: #676C80 !important;
    outline: none;
    font-size: 18px;
    color: #676C80!important;
    line-height: 40px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 40px;
  }
}
.create-name-button {
  .el-input__inner {
    background: rgba(27, 30, 38, 1)!important;
    border-color: #676C80 !important;
    outline: none;
    font-size: 18px;
    color: #676C80!important;
    line-height: 40px;
  }
  .el-input--prefix .el-input__inner {
    padding-left: 40px;
  }
}
</style>
<style lang="scss" scoped>
  .home-container {
    width: 100%;
    height: 100%;
    background: #000000;
    color: #B3B8C8;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: PingFangSC-Medium;
    transition: background .5s, color .5s;

    :not([class|="el"]) {
      transition: background-color .5s, color .5s;
    }
  }

  .control-container {
    width: 430px;
    height: 500px;
    border-radius: 20px;
    margin-left: 40px;
    position: relative;
    padding: 2px;
    background-image: linear-gradient(230deg, var(--background-image-color), rgba(61, 143, 255, 0) 50%);

    .control-content {
      width: 100%;
      height: 100%;
      padding: 0 40px;
      border-radius: 20px;
      background: rgba(27, 30, 38, 0.9);
    }

    .logo {
      position: absolute;
      top: 30px;
      left: 50%;
      width: 318px;
      transform: translate(-50%);
    }

    .control-region {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
    }

    .invite-title {
      display: inline-block;
      font-weight: 500;
      font-size: 28px;
      color: var(--invite-region);
      line-height: 34px;
      position: absolute;
      top: 170px;
    }

    .invite-info {
      display: inline-block;
      font-weight: 400;
      font-size: 20px;
      color: var(--invite-region);
      opacity: 0.6;
      line-height: 34px;
      position: absolute;
      top: 210px;
    }

    .button {
      width: 360px;
      height: 88px;
      background-image: linear-gradient(-45deg, #006EFF 0%, #0C59F2 100%);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
      border-radius: 8px;
      cursor: pointer;

      .title {
        font-size: 22px;
        color: #FFFFFF;
        line-height: 34px;
        margin-left: 9px;
      }
    }
    .button-t {
      width: 360px;
      height: 80px;
      background-image: linear-gradient(-45deg, #006EFF 0%, #0C59F2 100%);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
      border-radius: 8px;
      cursor: pointer;

      .title {
        font-size: 22px;
        color: #FFFFFF;
        line-height: 34px;
        margin-left: 7%;
      }
    }

    .enter-given-room-button {
      position: absolute;
      top: 338px;
      text-align: center;
      line-height: 88px;
    }
    .create-name-button {
      position: absolute;
      top: 160px;
      margin: auto 35px;
      .input {
        margin-top: 10px;
        background: rgba(27, 30, 38, 0.90)!important;
        border-color: transparent;
        outline: none;
        font-weight: 400;
        font-size: 22px;
        color: #676C80;
        line-height: 34px;
      }
    }
    .create-room-button {
      position: absolute;
      top: 380px;

      .create-room {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .create-room-mode {
        width: 100%;
        position: absolute;
        top: calc(100% + 4px);
        z-index: 10;
        background-color: var(--create-room-mode-color-bg);
        border: 1px solid rgba(255, 255, 255, 0.10);
        box-shadow: 0 1px 10px 0 #091D3B;
        border-radius: 8px;
        padding: 4px 0;
      }

      .connect-region {
        width: 100%;
        height: 6px;
      }

      .create-room-option {
        height: 48px;
        padding-left: 32px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
          background-color: var(--create-room-option);

          .title {
            color: var(--create-room-option-color);
          }

          .icon {
            background-color: var(--create-room-option-icon-color);
          }
        }

        .icon {
          background-color: var(--create-room-option-icon);
        }

        .title {
          font-weight: 400;
          font-size: 14px;
          color: var(--title-color-font);
        }
      }
    }
    .join-name-button {
      position: absolute;
      bottom: 140px;
      padding: 2px;

      .input {
        width: 100%;
        height: 100%;
        background: rgba(27, 30, 38, 0.90);
        border-color: transparent;
        outline: none;
        border-radius: 8px;
        font-weight: 400;
        font-size: 22px;
        color: #676C80;
        line-height: 34px;
        padding: 0 20px;
      }
    }
    .join-room-button {
      position: absolute;
      bottom: 50px;
      padding: 2px;

      .input {
        width: 212px;
        height: 100%;
        background: rgba(27, 30, 38, 0.90);
        border-color: transparent;
        outline: none;
        border-radius: 8px;
        font-weight: 400;
        font-size: 22px;
        color: #676C80;
        line-height: 34px;
        padding: 0 20px;
      }

      .title {
        cursor: pointer;
        text-align: center;
        width: 135px;
      }
    }
  }
  .local-stream-container {
    width: 480px;
    height: 360px;
    position: relative;
    flex-shrink: 0;
    .local-stream-content {
      width: 100%;
      height: 100%;
    }
    .local-stream-control {
      width: 100%;
      height: 30px;
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 10px;
      .control {
        margin-left: 10px;
      }
      .icon-class {
        color: #fff;
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .home-container {
      font-size: 14px;  /* Adjusting base font size */
    }

    .control-container {
      width: 90%;  /* Adjusting width */
      margin: 0 auto;
      border-radius: 10px;  /* Smaller border radius */
    }

    .control-content {
      padding: 0 10px;  /* Adjusting padding */
    }

    .logo {
      font-size: 40px;  /* Adjusting logo font size */
      top: 10px;
    }

    .invite-title, .invite-info {
      font-size: 18px;  /* Adjusting font size */
      top: 90px;
    }

    .button, .button-t {
      width: 100%;  /* Make buttons full width */
      height: 60px;  /* Adjusting height */
    }
    .button-t {
      width: 340px!important;
    }

    .create-name-button {
      top: 80px;
      margin: 0;
      .input {
        font-size: 18px;  /* Adjusting input font size */
      }
    }

    .create-room-button {
      top: 260px;
    }

    .join-name-button, .join-room-button {
      position: relative;
      width: 100%;  /* Make it full width */
      bottom: 0;
      margin-top: 10px;  /* Adding some top margin */
    }
  }
</style>
