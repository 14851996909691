<template>
  <div :class="['icon-box', `${!hideHoverEffect && 'hover-effect'}`, `${disabled && 'disabled'}`]">
    <span class="icon-content">
      <svg-icon v-if="iconName" :icon-name="iconName" />
      <slot></slot>
      <span class="title" :class="isActive ? 'title-active' : 'title'">{{ title }}</span>
    </span>
    <span v-if="hasMore" ref="moreSpanRef" class="icon-arrow" @click="clickMore">
      <svg-icon class="arrow" icon-name="arrow-up" size="small" />
    </span>
  </div>
</template>

<script>
  import SvgIcon from '../common/svg-icon.vue';

  export default {
    components: {
      SvgIcon,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      iconName: {
        type: String,
        default: undefined,
      },
      hasMore: {
        type: Boolean,
        default: false,
      },
      hideHoverEffect: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isActive: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      clickIcon() {
      },
      clickMore() {
        this.$emit('click-more');
      },
    },
  };
</script>

<style lang="scss" scoped>
* {
  transition: none !important;
}
.icon-box {
  width: 78px;
  height: 80px;
  position: relative;
  display: flex;
  cursor: pointer;
  padding: 10px 0;
  &.disabled {
    * {
      color: #484C59;
    }
  }
  &.hover-effect:hover {
    &:before{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 78px;
      height: 18px;
      opacity: 0.59;
      background: rgba(27,93,172,0.85);
      filter: blur(16px);
    }
    &:after{
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 78px;
      height: 3px;
      background: #1883FF;
    }
  }
  .icon-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .title {
      font-size: 12px;
      color: #ffffff;
    }
    .title-active {
      color: #B3B8C8;
    }
  }
  .icon-arrow {
    position: absolute;
    right: 0;
    top: 8px;
    width: 12px;
    height: 64px;
    &:hover {
      background: rgba(46,50,61,0.70);
    }
    .arrow {
      position: absolute;
      top: 20px;
      left: 0;
    }
  }
}
</style>
