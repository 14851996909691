import Vue from 'vue';
import VueRouter from 'vue-router';
import Index from '@/views/Index.vue';
import Home from '@/views/Home.vue';
import Room from '@/views/Room.vue';
import ModelRoom from '@/views/model/Room.vue';
import { getToken } from '../utils/authc'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/index',
    name: 'Index',
    component: Index,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/room',
    name: 'Room',
    component: Room,
  },
  {
    path: '/modelRoom',
    name: 'ModelRoom',
    component: ModelRoom,
  },
  {
    path: '/invite',
    name: 'Invite',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "invite" */ '../views/Invite.vue'),
  },
];

const router = new VueRouter({
  routes
})

export default router;

// 白名单，无需权限即可访问
const whiteList = ['/', '/index']
router.beforeEach((to, from, next) => {
  // 判断是否存在token
  if (getToken()) {
    if (to.path === '/') { // 存在token，且路由是登录页，则跳转首页
      next({
        path: '/'
      })
    } else {
      next()
    }
  } else {
    // 如果在白名单，直接放行，否则跳转到登录页
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/')
    }
  }
})
