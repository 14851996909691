<template>
  <div v-if="playRegionDomId !== enlargeDomId"
       class="user-stream-container single-stream"
    >
    <sketch class="overlay" v-if="isScreenStream"></sketch> <!-- 新增的覆盖层 -->
    <div :id="playRegionDomId" class="stream-region"></div>
<!--    <div class="center-user-info-container">-->
<!--      <img class="avatar-region" :src="stream.avatarUrl">-->
<!--    </div>-->
    <div class="corner-user-info-container">
      <svg-icon v-if="stream.getUserId() === homeUserId" class="master-icon" icon-name="user"></svg-icon>
      <svg-icon :icon-name="isStreamAudio ? 'mic-on' : 'mic-off'" class="icon-class"></svg-icon>
      <svg-icon v-if="isScreenStream" icon-name="screen-share" class="screen-icon"></svg-icon>
      <span class="user-name">{{ nickName}}</span>
      <span v-if="isScreenStream">共享的屏幕</span>
      <span v-if="userId === homeUserId && stream.getUserId() !== homeUserId" class="el-icon-close" @click="onRemoveUser(stream.getUserId(), nickName)"></span>
    </div>
  </div>
</template>

<script>
import SvgIcon from '../components/common/svg-icon.vue';
import user from '@/api/user'
import Sketch from "./Sketch";
export default {
  components: {
    Sketch,
    SvgIcon
  },
  props: {
    stream: {
      type: Object,
      required: true
    },
    enlargeDomId: {
      type: String,
      default: undefined
    },
    userAudio: {
      type: Object,
      required: true
    },
    showFullStream: {
      type: Boolean,
      default: false
    },
    userId: {
      type: String,
      default: undefined
    },
    homeUserId: {
      type: String,
      default: undefined
    }
  },
  data() {
    return {
      playRegionDomId: '',
      isScreenStream: '',
      showMasterIcon: true,
      streamRegionRef: null,
      centerUserInfoRef: null,
      isStreamAudio: false,
      nickName: ''
    };
  },
  computed: {
    // Similar computed properties
    // ...
  },
  watch: {
    userAudio (newVal) {
      if (newVal.userId === this.stream.getUserId()) {
        this.isStreamAudio = newVal.audio
      }
    },
    stream (newVal, oldVal) {
      if (this.showFullStream) {
        console.log(newVal, oldVal)
        this.playRegionDomId = `${this.stream.getUserId()}`
        if (this.stream.getType() === 'main' && this.stream.getUserId().indexOf('share') >= 0) {
          this.isScreenStream = true
        } else {
          this.isScreenStream = false
        }
        this.isStreamAudio = this.stream.hasAudio()
        console.log('音频状态',this.isStreamAudio)
        this.$nextTick(() => {
          oldVal.stop(oldVal.getUserId())
          newVal.stop(newVal.getUserId())
          oldVal.play(oldVal.getUserId(), { objectFit: 'contain', mirror: !this.isScreenStream }).catch();
          newVal.play(newVal.getUserId(), { objectFit: 'contain', mirror: !this.isScreenStream }).catch();
          user.getUserInfo(this.playRegionDomId).then(data => {
            if (data.result === '1') {
              this.nickName = data.data ? data.data.nickName : '游客'
            }
          })
        })
      }
    },
    showFullStream () {
      this.playRegionDomId = `${this.stream.getUserId()}`
      if (this.stream.getType() === 'main' && this.stream.getUserId().indexOf('share') >= 0) {
        this.isScreenStream = true
      } else {
        this.isScreenStream = false
      }
      this.isStreamAudio = this.stream.hasAudio()
      console.log('音频状态',this.isStreamAudio)
      this.$nextTick(() => {
        this.stream.stop(this.playRegionDomId)
        this.stream.play(this.playRegionDomId, { objectFit: 'contain', mirror: !this.isScreenStream }).catch();
        user.getUserInfo(this.playRegionDomId).then(data => {
          if (data.result === '1') {
            this.nickName = data.data ? data.data.nickName : '游客'
            console.log(this.nickName)
          }
        })
      })
    }
  },
  created() {
    this.playRegionDomId = `${this.stream.getUserId()}`
    if (this.stream.getType() === 'main' && this.stream.getUserId().indexOf('share') >= 0) {
      this.isScreenStream = true
    } else {
      this.isScreenStream = false
    }
    this.isStreamAudio = this.stream.hasAudio()
    console.log('音频状态',this.isStreamAudio)
    this.$nextTick(() => {
      this.stream.stop(this.playRegionDomId)
      this.stream.play(this.playRegionDomId, { objectFit: 'contain', mirror: !this.isScreenStream }).catch();
      user.getUserInfo(this.playRegionDomId).then(data => {
        if (data.result === '1') {
          this.nickName = data.data ? data.data.nickName : '游客'
          console.log(this.nickName)
        }
      })
    })
  },
  methods: {
    onRemoveUser (userId, nickName) {
      this.$emit('removeUser', userId, nickName);
    },
    updateNickName (item) {
      console.log(item)
      let user = JSON.parse(item)
      if (this.playRegionDomId === user.userId) {
        this.nickName = user.nickName
      }
    }
    // Other methods
    // ...
  },
  mounted() {
    this.$EventBus.$on("update_nick_name", this.updateNickName)
  }
}
</script>

<style lang="scss" scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10; /* 可以设置一个更高的值以确保它位于其他元素之上 */
  border: 2px solid #5e5e5e;
}
.user-stream-container {
  position: relative;
  .single-stream {
    padding: 4px;
  }
  .stream-region {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .corner-user-info-container {
    position: absolute;
    bottom: 0px;
    left: 0px;
    min-width: 118px;
    max-width: 100%;
    overflow: hidden;
    height: 30px;
    display: flex;
    background: rgba(0,0,0,0.60);
    color: #FFFFFF;
    align-items: center;
    align-content: center;
    padding: 0 10px 0 0;
    font-size: 14px;
    > *{
      margin-left: 8px;
      max-width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .master-icon {
      margin-left: -1px;
      width: 30px;
      height: 25px;
      margin-top: -4px;
    }
    .screen-icon {
      transform: scale(0.8);
    }
  }
  .center-user-info-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0D0F15;
    .avatar-region {
        width: 130px;
        height: 130px;
        border-radius: 50%;
    }
    .user-info {
      width: 130px;
      text-align: center;
      .avatar-region {
        width: 130px;
        height: 130px;
        border-radius: 50%;
      }
      .user-gender-name {
        svg {
          vertical-align: bottom;
        }
        .user-name {
          display: inline-block;
          margin-left: 6px;
          font-size: 14px;
          font-weight: 400;
          max-width: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: bottom;
        }
      }
    }
  }
}
</style>
