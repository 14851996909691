// 定义需要管理的数据
export default {
  // 客户端对象 Client
  client: {},
  otherNickName: {},
  isSignalReady: false,
  isTiwReady: false, // 白板是否已经Ready
  classInfo: {
    sdkAppId: undefined,
    userId: sessionStorage.getItem('TIW_CLASSINFO_USERID'),
    userSig: sessionStorage.getItem('TIW_CLASSINFO_USERSIG'),
    classId: sessionStorage.getItem('TIW_CLASSINFO_CLASSID'),
    nickname: sessionStorage.getItem('TIW_CLASSINFO_NICKNAME')
  },
  current: {
    toolType: 0,
    fileInfo: {},
  },

  boardSetting: {
  },
  rightBarShow: false
}
