// 可以进行异步操作，如果需要改变states，则必须通过commit调用mutations
import * as TYPES from './mutation-types'
export default {
  setClient ({commit}, client) {
    commit(TYPES.ADD_CLIENT, client)
  },
  setSignalReady({ commit }, payload) {
    commit('setSignalReady', payload);
  },
  setOtherNickName({ commit }, payload) {
    commit('setOtherNickName', payload);
  }
}
