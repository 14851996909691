import axios from 'axios'
import { getToken, removeToken } from './authc'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
})

// http request 拦截器
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // 避免token不能及时更新，每次请求都加上header
    // 客户端请求头设置token
    config.headers.authToken = getToken()
    return config
  },
  err => {
    return Promise.reject(err)
  })
// http response 拦截器
service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response) {
      // 401未认证，登录无效，删除本地token
      if (error.response.status === 401) {
        removeToken()
        window.location.reload()
      }
    }
    return Promise.reject(error.response)
  })
export default service
