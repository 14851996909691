import request from '@/utils/request'
export default {
  guestLogin (userName) {
    return request({
      url: 'guestLogin',
      method: 'post',
      params: {
        userName: userName
      }
    })
  },
  getUserInfo (userId) {
    return request({
      url: 'user/getUserInfo',
      method: 'post',
      params: {
        userId: userId
      }
    })
  },
  saveUserInfo (nickName) {
    return request({
      url: 'user/saveUserInfo',
      method: 'post',
      params: {
        nickName: nickName
      }
    })
  }
}
